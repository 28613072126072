import { useState } from 'react';
import classNames from 'classnames';
import Button from '@rio-cloud/rio-uikit/Button';
import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';

import type { Importance, Notification, State } from '../services/notificationsTypes';
import { FormattedTimestamp } from './FormattedTimestamp';
import { PostMessageType } from './Notifications';
import { useUpdateNotificationMutation } from '../services/notificationsApi';
import { useNotifications } from './useNotifications';

const getImportanceIconClassName = (importance: Importance) => {
    switch (importance) {
        case 'HIGH':
            return 'rioglyph rioglyph-error-sign text-color-danger';
        case 'MEDIUM':
            return 'rioglyph rioglyph-warning-sign text-color-warning';
        default:
            return '';
    }
};

type NotificationItemNewProps = {
    notification: Notification;
    isVehicleNotifications?: boolean;
};

// for now, replace the link to the livemonitor to use the new eventDetails view of the event-monitor Frontend
// https://livemonitor.rio.cloud/#map/8aeb43d7-26ef-4de4-b8a8-ddd80a59a8ee?assetIds=8aeb43d7-26ef-4de4-b8a8-ddd80a59a8ee&lock=true
// https://event-monitor.rio.cloud/#/eventDetails/8aeb43d7-26ef-4de4-b8a8-ddd80a59a8ee?assetIds
const replaceNotificationLink = (link: string | undefined) => {
    if (link?.startsWith('https://livemonitor.rio.cloud/')) {
        return `https://event-monitor.rio.cloud/#/eventDetails/${link.replace('https://livemonitor.rio.cloud/#map/', '').split('?')[0]}`;
    }
    return link;
};

export const NotificationItemNew = ({ notification, isVehicleNotifications = false }: NotificationItemNewProps) => {
    const { title, message, state, importance, createdAt, link: orgLink, sourceEntityName } = notification;

    const link = replaceNotificationLink(orgLink);

    const [isHover, setIsHover] = useState(false);

    const { refetch } = useNotifications();

    const [updateNotification] = useUpdateNotificationMutation();

    const handleNotificationClick = () => {
        if (link) {
            if (state === 'NEW') {
                // Mark the notification as read before opening the link
                updateSingleNotification('READ');
            }

            // When embedded in the notification bell in the header, close the popover
            window.postMessage({ type: PostMessageType.NOTIFICATIONS_POPOVER_CLOSED }, '*');

            if (window.top) {
                // Open the link in the parent window
                window.top.location.href = link;
            }
        }
    };

    const updateSingleNotification = (state: State) => {
        const updatedNotification = { ...notification, state };

        updateNotification(updatedNotification)
            .unwrap()
            .then(() => {
                console.log('Notification updated!');
                refetch();
            })
            .catch(err => console.error('Update failed:', err));
    };

    const handleMarkAsRead = (event: React.MouseEvent) => {
        event.stopPropagation();
        updateSingleNotification('READ');
    };

    const handleMarkAsUnread = (event: React.MouseEvent) => {
        event.stopPropagation();
        updateSingleNotification('NEW');
    };

    const isNew = state === 'NEW';

    const notificationClassNames = classNames(
        'bg-white border rounded',
        'padding-x-15 padding-y-10',
        link && 'cursor-pointer',
        (isNew || isHover) && 'shadow-subtle',
        !isNew && !isHover && 'opacity-40'
    );

    const readButtonClasses = classNames('position-absolute top-5 right-5');

    return (
        <div
            className='position-relative'
            data-testid='notification-list-item'
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <div className={notificationClassNames} onClick={handleNotificationClick}>
                <div className='display-flex gap-10'>
                    {importance !== 'LOW' && (
                        <div className='text-size-18 margin-top--2 margin-left--2'>
                            <span className={getImportanceIconClassName(importance)} />
                        </div>
                    )}
                    <div>
                        <div>
                            {!isVehicleNotifications && sourceEntityName && (
                                <span className='text-medium'>{`${sourceEntityName}: `}</span>
                            )}
                            <span className='text-medium'>{title}</span>
                        </div>
                        <div className='text-size-12 text-color-dark'>
                            <FormattedTimestamp timestamp={createdAt} />
                        </div>
                        {message && (
                            <div className='margin-top-5 line-height-125rel' data-testid='notification-message'>
                                {message}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isNew && isHover && (
                <SimpleTooltip content='Mark as read' placement='bottom-end'>
                    <Button
                        bsStyle='default'
                        variant='link'
                        bsSize='sm'
                        iconName='rioglyph-envelope-open text-size-16'
                        className={readButtonClasses}
                        onClick={handleMarkAsRead}
                        iconOnly
                    />
                </SimpleTooltip>
            )}
            {!isNew && isHover && (
                <SimpleTooltip content='Mark as unread' placement='bottom-end'>
                    <Button
                        bsStyle='default'
                        variant='link'
                        bsSize='sm'
                        iconName='rioglyph-envelope-indicator text-size-16'
                        className={readButtonClasses}
                        onClick={handleMarkAsUnread}
                        iconOnly
                    />
                </SimpleTooltip>
            )}
        </div>
    );
};
