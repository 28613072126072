import { useEffect, useState } from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import {
    useGetNotificationsAfterQuery,
    useGetNotificationsQuery,
    useSetBookmarkMutation,
} from '../services/notificationsApi';
import { getLatestTimestamp } from '../../utils/timestampUtil';
import { useUserId } from '../../configuration/tokenHandling/tokenSlice';
import { useLocale } from '../../configuration/lang/langSlice';
import { config } from '../../config';
import { useRemoteVehicleId } from '../widget/useRemoteVehicleId';
import type { Importance, Notification } from '../services/notificationsTypes';

const POLLING_INTERVAL_MS = config.isProduction ? 45_000 : 10_000;
const NOTIFICATION_API_LIMIT = 50;

export const mergeNotifications = (
    previousNotifications: Notification[],
    nextNotifications: Notification[]
): Notification[] => {
    // Merge the new notifications with the previous ones and make sure that previously
    // fetched notifications are updated for instance when their status changed
    const notificationsMap = new Map(
        [...previousNotifications, ...nextNotifications].map(notification => [notification.id, notification])
    );

    return [...notificationsMap.values()];
};

export const useNotifications = (filterBySourceEntity = false) => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [latestTimestamp, setLatestTimestamp] = useState<string | undefined>();

    const [newNotificationsCount, setNewNotificationCount] = useState(0);
    const [notificationLevel, setNotificationLevel] = useState<Importance>('LOW');

    const [afterParam, setAfterParam] = useState<string | undefined>(undefined);
    const [hasNext, setHasNext] = useState<boolean | undefined>(undefined);

    const [skipAfterQuery, setSkipAfterQuery] = useState(true);
    const [isPollingEnabled, setIsPollingEnabled] = useState(false);

    const userId = useUserId();
    const language = useLocale();
    let entitySourceId = useRemoteVehicleId();

    // Due to the remote vehicle hook, we have to reset the entitySourceId in case it is used
    // inside the Livemonitor but inside the notification bell so the notifications are not filtered
    if (!filterBySourceEntity) {
        entitySourceId = undefined;
    }

    const {
        data: fetchedData,
        isLoading,
        refetch,
    } = useGetNotificationsQuery(
        { limit: NOTIFICATION_API_LIMIT, language, entitySourceId },
        { pollingInterval: isPollingEnabled ? POLLING_INTERVAL_MS : undefined } // Poll only when enabled
    );

    const { data: dataAfter, isSuccess: isSuccessAfter } = useGetNotificationsAfterQuery(
        { limit: NOTIFICATION_API_LIMIT, after: afterParam, language, entitySourceId },
        { skip: skipAfterQuery }
    );

    useEffect(() => {
        // When entitySourceId changes, trigger the initial fetch and start polling
        if (entitySourceId) {
            setSkipAfterQuery(false); // Start the "after" query
            setIsPollingEnabled(true); // Enable polling
        }
    }, [entitySourceId]);

    useEffect(() => {
        if (fetchedData) {
            if (isEmpty(fetchedData.notifications)) {
                setNotifications([]);
            } else {
                setNotifications(previousNotifications => {
                    const fetchedNotifications = fetchedData?.notifications ?? [];

                    const mergedNotifications = mergeNotifications(previousNotifications, fetchedNotifications);

                    if (mergedNotifications.length > previousNotifications.length) {
                        setHasNext(fetchedNotifications.length === NOTIFICATION_API_LIMIT);
                        setAfterParam(fetchedData?.afterTag);
                    }

                    return mergedNotifications;
                });
            }
        }
    }, [fetchedData]);

    // Update the new notification count
    useEffect(() => {
        setNewNotificationCount(
            fetchedData?.notifications.filter(notification => notification.state === 'NEW').length ?? 0
        );
    }, [fetchedData]);

    // Update the latest timestamp
    useEffect(() => setLatestTimestamp(getLatestTimestamp(fetchedData?.notifications)), [fetchedData]);

    useEffect(() => {
        if (isSuccessAfter && dataAfter) {
            if (isEmpty(dataAfter.notifications)) {
                setNotifications([]);
            } else {
                setNotifications(previousNotifications =>
                    mergeNotifications(previousNotifications, dataAfter.notifications)
                );
            }
            setHasNext(dataAfter.hasNext);
            setSkipAfterQuery(true);
        }
    }, [dataAfter, isSuccessAfter]);

    // Set bookmark to mark all notifications as read for the given entitySourceId or all
    const [setBookmark] = useSetBookmarkMutation();
    const markAllAsRead = async () => {
        if (latestTimestamp) {
            await setBookmark({ userId, readUntil: latestTimestamp, entitySourceId }).unwrap();
            refetch();
        }
    };

    return {
        notifications,
        isLoading,
        newNotificationsCount,
        notificationLevel,
        hasNext,
        refetch,
        markAllAsRead,
        setSkipAfterQuery,
    };
};
