/* eslint-disable no-undef */
import * as Sentry from '@sentry/browser';
import { config } from '../../config';

const UNDEFINED_TOKEN = 'CREATE_RIO_FRONTEND_';

/**
 * https://trunkver.org/
 **/
const trunkVer: () => string | null = () => {
    const ciDate = import.meta.env.VITE_CI_DATE;
    const commitHash = import.meta.env.VITE_COMMIT_HASH; // git rev-parse --short HEAD
    const ciBuildNumber = import.meta.env.VITE_CI_BUILD_ID;
    if (!commitHash || !ciBuildNumber || !ciDate) {
        return null;
    }

    return `${ciDate}.0.0-${commitHash}-${ciBuildNumber}`;
};

if (import.meta.env.PROD) {
    // version and environment are defined in the webpack.define plugin
    const release = trunkVer() || config.serviceVersion;
    const environment = config.serviceEnvironment;
    const dsn = config.sentryToken;

    const hasToken = !!dsn && !dsn?.startsWith(UNDEFINED_TOKEN);

    if (hasToken) {
        Sentry.init({
            dsn,
            environment,
            release,
        });
    }
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const reportErrorToSentry = (...args: [any, any?]) => {
    Sentry.captureException(...args);
};
