import { AnimatePresence, motion } from '@rio-cloud/rio-uikit/framer-motion';

type StaggeredListProps<T> = {
    items: T[];
    keyItem: keyof T;
    renderItem: (item: T) => React.ReactNode;
    className?: string;
};

const STAGGER_FACTOR = 25;

export const StaggeredList = <T,>({ items, keyItem, renderItem, className }: StaggeredListProps<T>) => {
    return (
        <div className={className}>
            {/* @ts-ignore */}
            <AnimatePresence>
                {items.map((item, idx) => (
                    <motion.div
                        key={`${item[keyItem]}`}
                        animate={{ opacity: 1 }}
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0, transition: { duration: 0.15 } }}
                        transition={{ delay: 0.1 + idx / STAGGER_FACTOR }}
                    >
                        {renderItem(item)}
                    </motion.div>
                ))}
            </AnimatePresence>
        </div>
    );
};
