import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import ButtonToolbar from '@rio-cloud/rio-uikit/ButtonToolbar';
import isEmpty from 'lodash/fp/isEmpty';

import { dispatchMsgToParentIFrame, notificationCount } from '../../utils/iFrameHelper';
import { useNotifications } from './useNotifications';
import { NotificationItemNew } from './NotificationItemNew';
import { StaggeredList } from '../../components/StaggeredList';
import { useGroupedNotifications } from './useGroupedNotifications';
import { EmptyVehicleNotificationList } from './EmptyVehicleNotificationList';
import { NoNotificationsAvailable } from './NoNotificationsAvailable';
import { DateDivider } from './DateDivider';
import { NotificationsToolbar } from './NotificationsToolbar';
import { Loader } from './Loader';
import classNames from 'classnames';

type NotificationsProps = {
    isVehicleNotifications: boolean;
};

// TODO: Google analytics?
// TODO: add new translations to phrase
// TODO: mark only notifications as read for a vehicle when sourceEntityId is provided so it does not automatically mark notifications of another vehicle as read

export const NotificationsNew = ({ isVehicleNotifications }: NotificationsProps) => {
    const [showReadMessages, setShowReadMessages] = useState(false);

    const {
        notifications,
        isLoading,
        newNotificationsCount,
        hasNext: hasMoreNotifications,
        markAllAsRead,
        setSkipAfterQuery,
    } = useNotifications(isVehicleNotifications);

    useEffect(() => {
        // Send this event only when used outside the fleet monitor
        if (!isVehicleNotifications) {
            dispatchMsgToParentIFrame(notificationCount(newNotificationsCount));
        }
    }, [newNotificationsCount, isVehicleNotifications]);

    const toggleReadMessages = () => setShowReadMessages(value => !value);

    const onLoadMore = () => {
        setSkipAfterQuery(false);
    };

    const groupedNotifications = useGroupedNotifications(notifications, showReadMessages);

    if (isLoading) {
        return <Loader />;
    }

    if (isEmpty(notifications)) {
        return isVehicleNotifications ? <EmptyVehicleNotificationList /> : <NoNotificationsAvailable />;
    }

    const notificationListClassName = classNames(
        'notifications-list',
        'padding-bottom-15',
        !isVehicleNotifications && 'padding-top-0 padding-x-15'
    );

    const hasNewNotifications = newNotificationsCount > 0;

    // Show the load more button only when read messages are shown and there are more notifications on server side
    // or when there are new unread notifications and there are more notifications on server side.
    // Avoid showing the load more button when there are no new notifications
    const showLoadMoreButton =
        (showReadMessages && hasMoreNotifications) || (hasNewNotifications && hasMoreNotifications);

    return (
        <div className='position-relative'>
            <NotificationsToolbar
                showReadMessages={showReadMessages}
                hasUnreadMessages={hasNewNotifications}
                onToggleReadMessages={toggleReadMessages}
                onMarkAllAsRead={markAllAsRead}
                isVehicleNotifications={isVehicleNotifications}
            />

            <div className={notificationListClassName} data-testid='notifications-list' aria-label='notifications-list'>
                {!groupedNotifications.today && (
                    <div
                        key='today'
                        className='no-today-notifications text-center text-color-gray text-italic padding-y-20'
                    >
                        <FormattedMessage
                            id='intl-msg:notifications.noNewToday'
                            defaultMessage='No new notifications today'
                        />
                    </div>
                )}

                {groupedNotifications.today && (
                    <div key='today'>
                        <StaggeredList
                            className='display-flex flex-column gap-10'
                            items={groupedNotifications.today}
                            keyItem='id'
                            renderItem={notification => (
                                <NotificationItemNew
                                    notification={notification}
                                    isVehicleNotifications={isVehicleNotifications}
                                />
                            )}
                        />
                    </div>
                )}

                {groupedNotifications.yesterday && (
                    <div key='yesterday'>
                        <DateDivider>
                            <FormattedMessage
                                id='intl-msg:notifications.dateDivider.yesterday'
                                defaultMessage='Yesterday'
                            />
                        </DateDivider>
                        <StaggeredList
                            className='display-flex flex-column gap-10'
                            items={groupedNotifications.yesterday}
                            keyItem='id'
                            renderItem={notification => (
                                <NotificationItemNew
                                    notification={notification}
                                    isVehicleNotifications={isVehicleNotifications}
                                />
                            )}
                        />
                    </div>
                )}

                {groupedNotifications.older && (
                    <div key='older'>
                        <DateDivider>
                            <FormattedMessage id='intl-msg:notifications.dateDivider.older' defaultMessage='Older' />
                        </DateDivider>
                        <StaggeredList
                            className='display-flex flex-column gap-10'
                            items={groupedNotifications.older}
                            keyItem='id'
                            renderItem={notification => (
                                <NotificationItemNew
                                    notification={notification}
                                    isVehicleNotifications={isVehicleNotifications}
                                />
                            )}
                        />
                    </div>
                )}
            </div>

            {showLoadMoreButton && (
                <ButtonToolbar className='justify-content-center margin-bottom-15'>
                    <Button
                        bsStyle='default'
                        variant='link'
                        data-testid='load-more-btn'
                        onClick={onLoadMore}
                        iconName='rioglyph-arrow-down'
                    >
                        <FormattedMessage id='intl-msg:notifications.loadMore' />
                    </Button>
                </ButtonToolbar>
            )}
        </div>
    );
};
