import { FormattedMessage } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import classNames from 'classnames';

type NotificationsToolbarProps = {
    showReadMessages: boolean;
    hasUnreadMessages: boolean;
    onToggleReadMessages: () => void;
    onMarkAllAsRead: () => void;
    isVehicleNotifications: boolean;
};

export const NotificationsToolbar = (props: NotificationsToolbarProps) => {
    const { showReadMessages, onToggleReadMessages, onMarkAllAsRead, hasUnreadMessages, isVehicleNotifications } =
        props;

    const toolbarClassNames = classNames(
        'padding-y-5 padding-x-5',
        'display-flex',
        isVehicleNotifications
            ? 'justify-content-center margin-bottom-10'
            : 'justify-content-end margin-bottom-15 border border-bottom-only border-color-lighter'
    );

    return (
        <div className='toolbar bg-white'>
            <div className={toolbarClassNames}>
                <Button
                    bsStyle='default'
                    variant='link'
                    iconName='rioglyph-envelope'
                    data-testid='mark-all-read-btn'
                    onClick={onToggleReadMessages}
                >
                    {showReadMessages ? (
                        <FormattedMessage id='intl-msg:notifications.hideRead' defaultMessage='Hide read' />
                    ) : (
                        <FormattedMessage id='intl-msg:notifications.showRead' defaultMessage='Show read' />
                    )}
                </Button>
                <Button
                    bsStyle='default'
                    variant='link'
                    data-testid='mark-all-read-btn'
                    onClick={onMarkAllAsRead}
                    disabled={!hasUnreadMessages}
                >
                    <span className='rioglyph-icon-pair margin-right-10 text-size-18 margin-top--2'>
                        <span className='rioglyph rioglyph-stack' />
                        <span className='rioglyph rioglyph-ok text-size-10' />
                    </span>
                    <FormattedMessage id='intl-msg:notifications.markAllRead' defaultMessage='Mark all as read' />
                </Button>
            </div>
        </div>
    );
};
