import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '../../configuration/setup/hooks';

export type NotificationState = {
    entitySourceId: string | undefined;
};

const initialState: NotificationState = {
    entitySourceId: undefined,
};

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        entitySelected: (state, action) => {
            state.entitySourceId = action.payload;
        },
    },
});

export const { entitySelected } = notificationSlice.actions;

export const useEntitySourceId = () => useAppSelector(state => state.notifications.entitySourceId);

export default notificationSlice.reducer;
