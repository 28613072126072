import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../../config';
import { decodeNotificationsResponse } from './notificationsMapper';
import { prepareHeaders } from '../../configuration/tokenHandling/prepareHeaders';
import { handleResponse } from './responseHandler';
import type { Notification, NotificationsResponse } from './notificationsTypes';

// Query parameters types
type NotificationsQueryParams = {
    limit: number | undefined;
    language: string | undefined;
    entitySourceId?: string | undefined;
};

type NotificationsAfterQueryParams = NotificationsQueryParams & { after: string | undefined };

type SetBookmarkQueryParams = { userId: string; readUntil: string; entitySourceId?: string | undefined };

// Response types
type GetNotificationsResponse = {
    notifications: Notification[];
    afterTag: string | undefined;
};

type GetNotificationsAfterResponse = {
    notifications: Notification[];
    afterTag: string | undefined;
    hasNext: boolean;
};

export type BookmarkRequestBody = {
    read_until: string;
};

const NOTIFICATION_LIST_TAG = { type: 'Notification' as const, id: 'LIST' };
const notificationTag = (id: string) => ({ type: 'Notification' as const, id });

export const notificationsApi = createApi({
    reducerPath: 'notificationsApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.PLATFORM_NOTIFICATION_SERVICE, prepareHeaders }),
    tagTypes: ['Notification'],
    endpoints: builder => ({
        // Fetch initial page of notifications
        getNotifications: builder.query<GetNotificationsResponse, NotificationsQueryParams>({
            query: ({ limit = 1000, language, entitySourceId }) => {
                const urlSearchParams = new URLSearchParams();
                urlSearchParams.set('limit', limit.toString());
                if (language) {
                    urlSearchParams.set('language', language);
                }
                if (entitySourceId) {
                    urlSearchParams.set('source_entity_type', 'ASSET');
                    urlSearchParams.set('source_entity_identifier', entitySourceId);
                }

                return {
                    url: `/notifications?${urlSearchParams}`,
                    responseHandler: response => handleResponse(response),
                };
            },
            transformResponse: (rawResult: NotificationsResponse) => decodeNotificationsResponse(rawResult),
            providesTags: result => {
                // Provides a tag for each notification - Individual tags let you refetch only a single notification if it changes
                // The list tag is for bulk changes (e.g., removing notifications)
                return result
                    ? [...result.notifications.map(({ id }) => notificationTag(id)), NOTIFICATION_LIST_TAG]
                    : [NOTIFICATION_LIST_TAG];
            },
        }),

        // Fetch more pages of notifications
        getNotificationsAfter: builder.query<GetNotificationsAfterResponse, NotificationsAfterQueryParams>({
            query: ({ limit = 1000, after, language, entitySourceId }) => {
                const urlSearchParams = new URLSearchParams();
                urlSearchParams.set('limit', limit.toString());
                if (after) {
                    urlSearchParams.set('after', after);
                }
                if (language) {
                    urlSearchParams.set('language', language);
                }
                if (entitySourceId) {
                    // TODO: instead of hardcoding ASSET as type here, we should get the proper source entity type
                    urlSearchParams.set('source_entity_type', 'ASSET');
                    urlSearchParams.set('source_entity_identifier', entitySourceId);
                }

                return {
                    url: `/notifications?${urlSearchParams}`,
                    responseHandler: async (response: Response) => handleResponse(response),
                };
            },
            transformResponse: (rawResult: NotificationsResponse, _, arg) => {
                const decodedResponse = decodeNotificationsResponse(rawResult);
                return {
                    ...decodedResponse,
                    hasNext: decodedResponse.afterTag !== arg.after,
                };
            },
            providesTags: result =>
                result
                    ? [...result.notifications.map(({ id }) => notificationTag(id)), NOTIFICATION_LIST_TAG]
                    : [NOTIFICATION_LIST_TAG],
        }),

        // Update the state of a single notification
        updateNotification: builder.mutation<void, Notification>({
            query: ({ id, state }) => ({
                url: `/notifications/${id}`,
                method: 'PATCH',
                body: { state: { value: state } },
                responseHandler: async (response: Response) => handleResponse(response),
            }),
            invalidatesTags: (_, __, { id }) => [
                { type: 'Notification', id }, // Invalidate the updated notification
                NOTIFICATION_LIST_TAG, // Invalidate the notification list
            ],
        }),

        // TODO: ADD entitySourceId to allow marking all notifications as read for a specific entity
        // alternatively, iterate over all notifications and mark them as read via the updateNotification mutation
        setBookmark: builder.mutation<void, SetBookmarkQueryParams>({
            query: ({ userId, readUntil, entitySourceId }) => ({
                url: `/bookmarks/${userId}`,
                method: 'PUT',
                body: { read_until: readUntil },
                responseHandler: async (response: Response) => handleResponse(response),
            }),
            invalidatesTags: () => [NOTIFICATION_LIST_TAG],
        }),
    }),
});

export const {
    useGetNotificationsQuery,
    useGetNotificationsAfterQuery,
    useUpdateNotificationMutation,
    useSetBookmarkMutation,
} = notificationsApi;
