import * as t from 'io-ts';

const requiredNotificationFields = t.type({
    id: t.string,
    content: t.type({
        message: t.string,
        title: t.string,
    }),
    recipient: t.type({
        identifier: t.string,
        type: t.string,
    }),
    state: t.type({
        value: t.string,
    }),
});

const optionalNotificationFields = t.partial({
    translations: t.record(t.string, t.string),
    source_entity: t.partial({
        identifier: t.string,
        name: t.union([t.string, t.undefined]),
        type: t.string,
    }),
    created_at: t.string, // ISO 8601 format
    importance: t.union([t.literal('LOW'), t.literal('MEDIUM'), t.literal('HIGH'), t.undefined]),
    link: t.union([t.string, t.undefined]),
    language: t.union([t.string, t.undefined]),
});

const notification = t.intersection([requiredNotificationFields, optionalNotificationFields]);

const notifications = t.array(notification);

const link = t.type({
    href: t.string,
});

const paginationLinks = t.type({
    next: t.union([link, t.undefined]),
    self: link,
});

export const notificationsResponse = t.type({
    items: notifications,
    _links: paginationLinks,
});

export type ApiNotification = t.TypeOf<typeof notification>;

export type NotificationsResponse = t.TypeOf<typeof notificationsResponse>;

export type State = 'NEW' | 'READ' | 'UNKNOWN';

export type Importance = 'LOW' | 'MEDIUM' | 'HIGH';

export type Notification = {
    createdAt: string;
    id: string;
    message: string;
    state: State;
    importance: Importance;
    link: string | undefined;
    title: string;
    sourceEntityId?: string | undefined;
    sourceEntityName?: string | undefined;
    sourceEntityType?: string | undefined;
};
