import { FormattedMessage } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';

export const LoadMore = ({ onClick }: { onClick: () => void }) => (
    <div className='display-flex justify-content-center width-100pct'>
        <Button
            bsStyle='default'
            variant='outline'
            className='border-none hover-bg-lighter hover-text-color-highlight-dark'
            iconName='rioglyph-arrow-down'
            data-testid='load-more-btn'
            onClick={onClick}
        >
            <FormattedMessage id='intl-msg:notifications.loadMore' />
        </Button>
    </div>
);
