import { useEffect } from 'react';
import usePostMessage, { type RemoteAction } from '@rio-cloud/rio-uikit/usePostMessage';

import { entitySelected, useEntitySourceId } from '../notifications/notificationSlice';
import { useAppDispatch } from '../../configuration/setup/hooks';

const REMOTE_EVENT_ASSET_SELECTED = 'EVENT_SELECT_ASSET';
const REMOTE_EVENT_WIDGET_LOADED = 'EVENT_WIDGET_LOADED';

export const useRemoteVehicleId = () => {
    const dispatch = useAppDispatch();

    const vehicleId = useEntitySourceId();

    const { sendMessageToParent } = usePostMessage('', (remoteAction: RemoteAction) => {
        if (remoteAction.type === REMOTE_EVENT_ASSET_SELECTED) {
            console.log('change remote vehicle id', remoteAction.payload.id);

            dispatch(entitySelected(remoteAction.payload.id));
        }
    });

    // When the application is loaded, send a message to the parent window to notify that the widget is loaded
    // and to receive the initial state data like if there is a vehicle selected or not. This information is
    // received as individual events, means the asset selected event handler above will receive the right asset id
    // on load
    useEffect(() => sendMessageToParent({ type: REMOTE_EVENT_WIDGET_LOADED } as RemoteAction), []);

    return vehicleId;
};
