import type { PropsWithChildren } from 'react';

type NotificationListProps = {
    listLabel?: string;
};

export const NotificationList = (props: PropsWithChildren<NotificationListProps>) => (
    <div className='notification' data-testid='notifications-div'>
        <div
            className='children-last-border-bottom-none'
            aria-label={props.listLabel ? props.listLabel : 'notifications-list'}
            data-testid='notifications-list'
        >
            {props.children}
        </div>
    </div>
);
