export const handleResponse = async (response: Response) => {
    if (response.ok) {
        if (response.status === 204) {
            return;
        }
        try {
            return await response.json();
        } catch (err) {
            throw new Error(`${response.status} Invalid payload: ${JSON.stringify(err)}`);
        }
    }
    if (response.status === 401) {
        throw new Error(`${response.status} Unauthorized: ${response.statusText}`);
    }
    throw new Error(`${response.status} Backend error: ${response.statusText}`);
};
