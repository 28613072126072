/* eslint-disable camelcase, no-console */
import {
    type UserProfile as Profile,
    User,
    UserManager,
    type UserManagerSettings,
    WebStorageStateStore,
    InMemoryWebStorage,
    Log,
} from 'oidc-client-ts';
import join from 'lodash/fp/join';

import { mapUserProfile } from './userProfile';
import { config } from '../../config';
import type { OAuthConfig, Tokens } from '..';
import type { AccessToken } from '../tokenHandling/tokenSlice';
import type { UserProfile } from './loginSlice';
import { jwtDecode, type JwtPayload } from 'jwt-decode';

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.signinSilent().catch((error: Error) => {
        if (error.message === 'login_required') {
            oauthConfig.onSessionExpired();
        } else {
            setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
        }
    });
};

export const enableOidcDebugLogging = () => {
    // eslint-disable-next-line no-console
    console.log('Setting oidc-client log level to DEBUG');
    Log.setLogger(console);
    Log.setLevel(Log.DEBUG);
};

export type SessionRenewedResult = {
    accessToken: AccessToken;
    idToken: Profile;
    profile: UserProfile;
    locale: string;
};

export const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
    accessToken: result.access_token,
    idToken: result.profile,
    locale: result.profile?.locale ?? 'en-GB',
    profile: mapUserProfile(result.profile),
});

const isRunningInIframe = () => {
    try {
        return window.self !== window.top;
    } catch {
        return true;
    }
};

export const createUserManager = () => {
    const redirectUri = config.login.redirectUri;
    const silentRedirectUri = config.login.silentRedirectUri;

    const settings: UserManagerSettings = {
        authority: `${config.login.authority}`,
        client_id: `${config.login.clientId}`,
        loadUserInfo: false,
        redirect_uri: `${redirectUri}`,
        response_type: 'code',
        scope: join(' ', config.login.oauthScope),
        silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
        includeIdTokenInSilentRenew: false,
        automaticSilentRenew: true,
        staleStateAgeInSeconds: 600,
        userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
        monitorSession: !isRunningInIframe(),
    };

    return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.events.addUserLoaded(user => {
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
    });

    userManager.events.addUserUnloaded(() => {
        oauthConfig.onSessionExpired();
    });

    userManager.events.addSilentRenewError(() => {
        retrySigninSilent(oauthConfig, userManager);
    });

    userManager.events.addUserSignedOut(() => {
        oauthConfig.onSessionExpired();
    });

    return userManager;
};

export const configureMockUserManager = (oauthConfig: OAuthConfig, { accessToken, idToken }: Tokens): UserManager => {
    if (import.meta.env.DEV) {
        console.warn('[configuration/login/oidc-session] Using mocked authorization due to config setting');
    }

    const signinSilent = () => {
        const jwtPayload: JwtPayload | null = idToken ? jwtDecode(idToken) : null;
        const userSettings = {
            access_token: accessToken || 'valid-mocked-oauth-bogus-token',
            profile: {
                iss: jwtPayload?.iss || 'Issuer Identifier',
                sub: jwtPayload?.sub || 'prod-rio-users:mock-user',
                aud: jwtPayload?.aud || 'Audience(s): client_id',
                exp: jwtPayload?.exp || 10,
                nbf: jwtPayload?.nbf || 5,
                iat: jwtPayload?.iat || 5,
                jti: jwtPayload?.jti || 'JWT ID',
                account: 'mockaccount',
                azp: 'test-client',
                email: 'test@example.com',
                family_name: 'Client',
                given_name: 'Test',
                name: 'Test Client',
                locale: config.login.mockLocale,
                tenant: config.login.mockTenant,
            },
            id_token: 'id_token',
            session_state: 'session_state',
            refresh_token: 'refresh_token',
            token_type: 'token_type',
            scope: 'scope',
            expires_at: 100000,
            state: 'state',
        };

        const user = new User(userSettings);
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
        return Promise.resolve(user);
    };

    const clearStaleState = () => {
        console.info('[configuration/login/oidc-session] Stale state cleared');
        return Promise.resolve();
    };

    const getUser = () => {
        console.info('[configuration/login/oidc-session] getUser() called');
        return Promise.resolve(null);
    };

    return { signinSilent, clearStaleState, getUser } as UserManager;
};
