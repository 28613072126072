import { FormattedMessage } from 'react-intl';

import { NotificationList } from './NotificationList';

export const EmptyVehicleNotificationList = () => (
    <NotificationList listLabel='no-notifications'>
        <div className='margin-top-25 padding-x-15 text-center'>
            <div className='margin-bottom-15'>
                <FormattedMessage
                    id='intl-msg:notifications.vehicleNotificationsUpdate'
                    values={{
                        b: chunk => <b>{chunk}</b>,
                    }}
                    defaultMessage={
                        '<b>We have revised our vehicle notifications.</b> You can now decide for yourself what you want to be ' +
                        'informed about. And about much more than just the <b>downtimes.</b>'
                    }
                />
            </div>
            <a
                href='https://event-monitor.rio.cloud/#/rules/create'
                className='btn btn-primary btn-icon-right'
                target='_blank'
                rel='noreferrer'
            >
                <FormattedMessage id='intl-msg:notifications.createRuleButton' defaultMessage='Create rule now' />
                <span className='rioglyph rioglyph-new-window' />
            </a>
            <div className='alert alert-info margin-top-50 text-left'>
                <div className='display-flex gap-10'>
                    <span className='text-color-info text-size-h4 rioglyph rioglyph-info-sign' />
                    <div>
                        <FormattedMessage
                            id='intl-msg:notifications.downtimeTemplateHint'
                            defaultMessage={
                                'Would you like to continue receiving similar notifications as before? Use one of our templates below ' +
                                'to quickly set up these notifications with just a few clicks.'
                            }
                        />
                        <div className='margin-top-25'>
                            <div className='display-grid grid-cols-2 gap-10'>
                                {/* Old exception: "Vehicle stands still for > 24 hours." */}
                                <a
                                    href='https://event-monitor.rio.cloud/#/rules/create?scope=%28type%3AACCOUNT%29&condition=%28type%3ADRIVING_STATE%2Cstatus%3ASTAND_STILL%29&minimumMatchDuration=1440'
                                    className='alert-link text-medium text-center display-flex flex-column align-items-center gap-10'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <div className='display-flex'>
                                        <span className='rioglyph-icon-pair text-size-h1'>
                                            <span className='rioglyph rioglyph-truck' />
                                            <span className='rioglyph rioglyph-parking bg-info text-color-white' />
                                        </span>
                                    </div>
                                    <div className='line-height-125rel'>Vehicle downtime template </div>
                                </a>

                                {/* Old exception: "No vehicle positions received for > 12 hours." */}
                                <a
                                    href='https://event-monitor.rio.cloud/#/rules/create?scope=%28type%3AACCOUNT%29&condition=%28type%3AIGNITION%2Cstatus%3AIGNITION_OFF%29&minimumMatchDuration=720'
                                    className='alert-link text-medium text-center display-flex flex-column align-items-center gap-10'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <div className='display-flex'>
                                        <span className='rioglyph-icon-pair text-size-h1'>
                                            <span className='rioglyph rioglyph-truck' />
                                            <span className='rioglyph rioglyph-map-marker bg-info text-color-white' />
                                        </span>
                                    </div>
                                    <div className='line-height-125rel'>No vehicle position template</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </NotificationList>
);
