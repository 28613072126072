import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { Notifications } from '../notifications/Notifications';
import { NotificationsNew } from '../notifications/NotificationsNew';

const AppLayout = () => {
    const url = new URL(window.location.href);
    console.log('url.pathname', url.pathname);

    // For now, we use the dedicated route as feature toggle to test the component as widget in the Livemonitor
    // The dedicated route will be needed in any case for the Livemonitor to filter notifications by vehicle id.
    // Note that the flag is also used to apply different styling when being embedded in the Livemonitor as well
    // as showing different empty states.
    const showVehicleNotifications = url.pathname === '/vehicleNotifications';

    const showNewNotifications = url.pathname === '/new';

    return (
        <ApplicationLayout className='PlatformNotifications bg-white' data-testid='platform-notifications'>
            {showVehicleNotifications || showNewNotifications ? (
                <NotificationsNew isVehicleNotifications={showVehicleNotifications} />
            ) : (
                <Notifications />
            )}
        </ApplicationLayout>
    );
};

export default AppLayout;
