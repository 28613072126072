import qs from 'qs';

import { decode } from './iotsDecoder';
import {
    notificationsResponse,
    type Importance,
    type Notification,
    type NotificationsResponse,
    type State,
} from './notificationsTypes';

const determineState = (rawState: string): State => {
    switch (rawState) {
        case 'NEW':
            return 'NEW';
        case 'READ':
            return 'READ';
        default:
            return 'UNKNOWN';
    }
};

export const determineImportance = (rawImportance: string | undefined): Importance => {
    switch (rawImportance) {
        case 'LOW':
            return 'LOW';
        case 'MEDIUM':
            return 'MEDIUM';
        case 'HIGH':
            return 'HIGH';
        default:
            return 'LOW';
    }
};

export type MappedNotifications = {
    notifications: Notification[];
    afterTag: string | undefined;
};

export const decodeNotificationsResponse = (parsedObject: NotificationsResponse): MappedNotifications => {
    if (parsedObject.items.length === 0) {
        return { notifications: [], afterTag: undefined };
    }

    const decoded = decode(parsedObject, notificationsResponse);
    const nextLink = decoded._links.next?.href;
    const queryString = nextLink ? nextLink.substring(nextLink.indexOf('?') + 1) : '';

    const { after } = qs.parse(queryString);

    return {
        notifications: decoded.items
            .map(rawNotification => ({
                createdAt: rawNotification.created_at ?? '',
                id: rawNotification.id,
                importance: determineImportance(rawNotification.importance),
                link: rawNotification.link,
                message: rawNotification.content.message,
                state: determineState(rawNotification.state.value),
                title: rawNotification.content.title,
                sourceEntityId: rawNotification.source_entity?.identifier,
                sourceEntityName: rawNotification.source_entity?.name,
                sourceEntityType: rawNotification.source_entity?.type,
            }))
            .filter(rawNotification => rawNotification.state !== 'UNKNOWN'),
        afterTag: after ? (after as string) : undefined,
    };
};
