import { useMemo } from 'react';

import type { Notification } from '../services/notificationsTypes';

const groupNotificationsByDate = (notifications: Notification[]) => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const groups = notifications.reduce<Record<string, Notification[]>>((acc, notification) => {
        const notificationDate = new Date(notification.createdAt);
        const dateKey =
            notificationDate.toDateString() === today.toDateString()
                ? 'today'
                : notificationDate.toDateString() === yesterday.toDateString()
                  ? 'yesterday'
                  : 'older';

        if (!acc[dateKey]) {
            acc[dateKey] = [];
        }
        acc[dateKey].push(notification);

        return acc;
    }, {});

    // Sort notifications in each group by descending date (newest first)
    Object.keys(groups).forEach(key => {
        groups[key].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    });

    return groups;
};

const withoutReadNotifications = (notification: Notification) => notification.state !== 'READ';

export const useGroupedNotifications = (notifications: Notification[], showReadNotifications: boolean) => {
    return useMemo(
        () =>
            groupNotificationsByDate(
                showReadNotifications ? notifications : notifications.filter(withoutReadNotifications)
            ),
        [notifications, showReadNotifications]
    );
};
