import type { PropsWithChildren } from 'react';
import Divider from '@rio-cloud/rio-uikit/Divider';

export const DateDivider = ({ children }: PropsWithChildren) => (
    <Divider dividerColor='lighter'>
        <div className='date-divider tag tag-small border-color-lighter text-color-dark text-medium text-size-11 padding-y-2 rounded'>
            <span>{children}</span>
        </div>
    </Divider>
);
