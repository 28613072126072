import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../features/app/appSlice';
import notificationSlice from '../../features/notifications/notificationSlice';
import { notificationsApi } from '../../features/services/notificationsApi';

const getIsDevToolsEnabled = () => (import.meta.env.DEV ? true : localStorage.getItem('devTools') === 'true');

const rootReducer = combineReducers({
    config: configReducer,
    lang: langReducer,
    app: appReducer,
    notifications: notificationSlice,
    login: loginReducer,
    tokenHandling: tokenReducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
    configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(notificationsApi.middleware),
        preloadedState,
        devTools: getIsDevToolsEnabled(),
    });

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type RootStore = ReturnType<typeof setupStore>;
export type RootDispatch = typeof store.dispatch;

export const store: RootStore = setupStore();

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
