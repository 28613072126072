import type { Importance } from '../features/services/notificationsTypes';

export const NOTIFICATION_COUNT = 'NOTIFICATION_COUNT';
export const MAX_NOTIFICATION_PRIORITY = 'MAX_NOTIFICATION_PRIORITY';

interface IFrameWindow extends Window {
    parentIFrame?: any;
    iFrameResizer?: any;
}

interface IframeNotificationCountAction {
    type: typeof NOTIFICATION_COUNT;
    payload: {
        count: number;
    };
}

interface IframeNotificationPriorityAction {
    type: typeof MAX_NOTIFICATION_PRIORITY;
    payload: {
        importance: Importance;
    };
}

export const notificationCount = (count = 0): IframeNotificationCountAction => ({
    type: NOTIFICATION_COUNT,
    payload: {
        count,
    },
});

export const maxNotificationLevel = (importance: Importance): IframeNotificationPriorityAction => ({
    type: MAX_NOTIFICATION_PRIORITY,
    payload: {
        importance,
    },
});

export type IframeActionType = IframeNotificationCountAction | IframeNotificationPriorityAction;

export const dispatchMsgToParentIFrame = (action: IframeActionType) => {
    const payloadToSend = constructPayload(action);

    if ('parentIFrame' in window) {
        (window as IFrameWindow).parentIFrame.sendMessage(payloadToSend, '*');
    }
};

const constructPayload = (action: IframeActionType): IframeActionType => {
    switch (action.type) {
        case NOTIFICATION_COUNT: {
            return { type: NOTIFICATION_COUNT, payload: action.payload };
        }
        case MAX_NOTIFICATION_PRIORITY: {
            return { type: MAX_NOTIFICATION_PRIORITY, payload: action.payload };
        }
    }
};
