import { createSlice } from '@reduxjs/toolkit';

export interface AppState {
    sessionExpiredAcknowledged: boolean;
}

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: state => {
            state.sessionExpiredAcknowledged = true;
        },
    },
});

export default appSlice.reducer;
