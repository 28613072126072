import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import type { Importance, State } from '../services/notificationsTypes';
import { FormattedMessage } from 'react-intl';
import type { FC } from 'react';

interface ImportanceGlyphProps {
    importance: Importance;
    state: State;
}

export const ImportanceGlyph: FC<ImportanceGlyphProps> = props => {
    let tooltipMessageId;
    let glyphClassName;
    switch (props.importance) {
        case 'HIGH':
            tooltipMessageId = 'intl-msg:notifications.importance-high';
            glyphClassName = 'rioglyph text-size-16 rioglyph-error-sign text-color-danger';
            break;
        case 'MEDIUM':
            tooltipMessageId = 'intl-msg:notifications.importance-medium';
            glyphClassName = 'rioglyph text-size-16 rioglyph-warning-sign text-color-warning';
            break;
        default:
            tooltipMessageId = 'intl-msg:notifications.importance-low';
            glyphClassName = 'rioglyph text-size-16 rioglyph-info-sign text-color-dark';
    }
    return (
        <OverlayTrigger
            placement={'bottom-start'}
            overlay={
                <Tooltip id='tooltip' width='auto' className='text-size-10'>
                    <FormattedMessage id={tooltipMessageId} />
                </Tooltip>
            }
        >
            <span className={[glyphClassName].join(' ')} data-testid={'importance-glyph'} />
        </OverlayTrigger>
    );
};
