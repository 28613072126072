import { FormattedMessage } from 'react-intl';

import { NotificationList } from './NotificationList';

export const NoNotificationsAvailable = () => (
    <NotificationList listLabel={'no-notifications'}>
        <div className={'height-50 display-flex justify-content-center align-items-center text-center'}>
            <FormattedMessage id={'intl-msg:notifications.noNotifications'} />
        </div>
    </NotificationList>
);
